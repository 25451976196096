<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="header">
      Dispensation
    </div>
    <div class='radio-toolbar'>
      <button class="nav-option move-right" :class="!existingDispensationView ? 'active' : ''"
        v-on:click="setSearchExistingDispensationView(false)">{{ $t('add_new_dispensation') }}</button>
      <button class="nav-option move-left" :class="existingDispensationView ? 'active' : ''"
        v-on:click="setSearchExistingDispensationView(true)">{{ $t('search_existing_dispensation') }}</button>
    </div>
    <div v-if="!existingDispensationView">
      <SearchComponent :selectedSearchCriteria="searchKeyEpisodeList" :search-options="episodeSearchOptions" @search-data-by-key="handleSearchEpisodes" />
      <div v-if="totalEpisodes !== 0" id="dispensationDetailsPage">
        <dispensation-details-page
          :isSearchExistingDispensation="existingDispensationView"
          :detailsLoading="detailsLoading"
          :selectedPage="episodeDetailsCurrentPage"
          :tableColumns="episodeDataColumns"
          :tableRows="episodeDataRows"
          :columns="episodeColumnList"
          :totalHits="totalEpisodes"
          :pageSize="pageSize"
          @turn-page="handlePageTurn"
        />
      </div>
      <div v-if="!detailsLoading && totalEpisodes === 0" class="center-text">
        {{ $t('no_episodes_to_display') }}
      </div>
      <div v-if="totalEpisodes === 0" class="center-content">
        <p class="text-with-button">
          {{ 'If the patient you are searching for is not present you can create a new enrollment by clicking on the button below' }}
        </p>
        <button class="nav-option" :class="'active'" v-on:click="addEpisode()">{{ $t('_add_patient') }}</button>
      </div>
    </div>
    <div v-else>
        <SearchComponent :selectedSearchCriteria="searchKeyDispensationList" :search-options="dispensationSearchOptions" @search-data-by-key="handleSearchDispensations" />
        <div v-if="totalDispensations !== 0" id="dispensationDetailsPage">
          <dispensation-details-page
            :isSearchExistingDispensation="existingDispensationView"
            :detailsLoading="detailsLoading"
            :selectedPage="dispensationDetailsCurrentPage"
            :tableColumns="dispensationDataColumns"
            :tableRows="filteredDispensationDataRows"
            :columns="dispensationColumnList"
            :totalHits="totalDispensations"
            :pageSize="pageSize"
            @turn-page="handlePageTurn"
          />
        </div>
        <div v-if="!detailsLoading && totalDispensations === 0" class="center-text">
          {{ $t('no_episodes_to_display') }}
        </div>
        <div v-if="totalDispensations === 0" class="center-content">
          <p class="text-with-button">
            {{ 'If the patient you are searching for is not present you can create a new enrollment by clicking on the button below' }}
          </p>
          <button class="nav-option" :class="'active'" v-on:click="addEpisode()">{{ $t('_add_patient') }}</button>
        </div>
      </div>
  </div>
</template>

<script>
import DispensationDetailsPage from './components/DispensationDetailsPage.vue'
import SearchComponent from './components/SearchComponent.vue'
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Dispensation',
  data () {
    return {
      episodeSearchOptions: [
        { Value: 'id', Key: 'id' },
        { Value: 'name', Key: 'name' },
        { Value: 'primaryPhoneNumber', Key: 'primaryPhoneNumber' }
      ],
      dispensationSearchOptions: [
        { Value: 'id', Key: 'id' },
        { Value: 'dispensationId', Key: 'dispensationId' }
      ],
      filteredDispensationDataRows: [],
      breadcrumbs: [
        {
          label: 'Dashboard',
          link: '/dashboard/overview',
          class: ''
        },
        {
          label: '_dispensation',
          link: '',
          class: 'active'
        }
      ]
    }
  },
  components: {
    DispensationDetailsPage,
    SearchComponent,
    Breadcrumbs
  },
  methods: {
    ...mapActions('Dispensation', [
      'setSearchKeyEpisodeList',
      'setSearchKeyDispensationDetails',
      'setSearchValueEpisodeList',
      'setSearchValueDispensationList',
      'loadEpisodeList',
      'loadDispensationList',
      'setCurrentPageSearchEpisodes',
      'setCurrentPageSearchDispensations',
      'setSearchExistingDispensationView'
    ]),
    async handleSearchEpisodes (data) {
      const { searchKey, searchValue } = data
      this.setSearchKeyEpisodeList(searchKey)
      this.setSearchValueEpisodeList(searchValue)
      await this.loadEpisodeList()
    },
    async handleSearchDispensations (data) {
      const { searchKey, searchValue } = data
      this.setSearchKeyDispensationDetails(searchKey)
      this.setSearchValueDispensationList(searchValue)
      await this.loadDispensationList()
      this.filterDispensationsForSelectedPage()
    },
    async handlePageTurn (number) {
      if (!this.existingDispensationView) {
        if (number < 1 || number > this.numberPagesEpisodesList) {
          return
        }
        this.setCurrentPageSearchEpisodes(number)
        this.loadEpisodeList()
      } else {
        if (number < 1 || number > this.numberPagesDispensationList) {
          return
        }
        this.setCurrentPageSearchDispensations(number)
        this.filterDispensationsForSelectedPage()
      }
    },
    addEpisode () {
      this.$router.push('/dashboard/enrollment')
    },
    filterDispensationsForSelectedPage () {
      const sortedData = this.dispensationDataRows.sort((a, b) => a.id - b.id)
      const start = (this.dispensationDetailsCurrentPage - 1) * this.pageSize
      const end = start + this.pageSize
      this.filteredDispensationDataRows = sortedData.slice(start, end)
    }

  },
  computed: {
    ...mapState('Dispensation', [
      'existingDispensationView',
      'totalEpisodes',
      'totalDispensations',
      'episodeDataColumns',
      'episodeDataRows',
      'dispensationDataColumns',
      'dispensationDataRows',
      'detailsLoading',
      'episodeDetailsCurrentPage',
      'dispensationDetailsCurrentPage',
      'episodeColumnList',
      'dispensationColumnList',
      'numberPagesEpisodesList',
      'numberPagesDispensationList',
      'pageSize',
      'searchKeyEpisodeList',
      'searchKeyDispensationList',
      'searchValueEpisodeList',
      'searchValueDispensationList'
    ])
  }
}
</script>

<style scoped>
.header {
  font-family: 'IBM Plex Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  padding-bottom: 20px;
  align-items: center;
  letter-spacing: 0.005em;
  color: var(--day-select-color);
  padding-left: 10px;
}

#dispensationDetailsPage {
  margin-top: 100px;
}

.radio-toolbar {
  text-align: center;
  margin-bottom: 20px;
}

.nav-option {
  display: inline-block;
  cursor: pointer;
  background-color: var(--background-color-primary);
  text-transform: uppercase;
  color: var(--primary-theme);
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  border: 1px solid var(--primary-theme);
  min-width: 240px;
  text-align: center;
  height: 48px;
  margin: unset !important;
  border-radius: 8px;
}

.move-left {
  position: relative;
  border-radius: 0px 8px 8px 0px;
}

.move-right {
  position: relative;
  border-radius: 8px 0px 0px 8px;
}

.active {
  background-color: var(--primary-theme);
  color: white;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.center-text {
  text-align: center;
  margin-top: 100px;
}

.text-with-button {
  padding-bottom: 20px;
}</style>
