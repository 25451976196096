<template>
  <ol class='breadcrumb'>
    <li v-bind:key="item.label" v-for="item in items" :class="item.class"><a v-bind:href="item.link">{{ $t(item.label) }}</a></li>
  </ol>
</template>
<script>
export default {
  name: 'Breadcrumbs',
  props: {
    items: {
      default: []
    }
  }
}
</script>
<style lang='scss' scoped>

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: var(--light-hover-color);
  border-radius: 4px;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}
.breadcrumb > li > a {
    text-transform: none;
    color: var(--breadcrumbs-text-color);
    text-decoration: none;
}
.breadcrumb > li > a:hover {
  text-decoration: underline;
}
.breadcrumb > .active a {
  color: var(--primary-theme);
}

</style>
